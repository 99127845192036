import {
  ref, onMounted,
} from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()
  const isBusy = ref(false)
  const refUserListTable = ref(null)
  const searchQuery = ref('')
  const users = ref([])
  const filterOn = ['name', 'email', 'role', 'phoneNumber', 'status']
  // Table Handlers
  const tableColumns = [
    {
      key: 'name', sortable: true, filterable: true, filter: 'string',
    },
    {
      key: 'email', sortable: true, filterable: true, filter: 'string',
    },
    {
      key: 'phoneNumber', label: 'Phone', sortable: true, filterable: true, filter: 'string',
    },
    {
      key: 'role', sortable: true, filterable: true, filter: 'string',
    },
    {
      key: 'customerName',
      label: 'Customer',
      formatter: title,
      sortable: true,
      filterable: true,
      filter: 'string',
    },
    {
      key: 'siteNames', label: 'Sites', sortable: true, filterable: true, filter: 'array',
    },
    {
      key: 'status', sortable: true, filterable: true, filter: 'string',
    },
    { key: 'actions' },
  ]

  const fetchUsers = (ctx) => {
    isBusy.value = true
    store
      .dispatch('app-user/fetchUsers')
      .then(response => {
        users.value = response.data
        isBusy.value = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteThisUser = id => {
    isBusy.value = true
    store
      .dispatch('app-user/deleteUser', id)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'User deleted successfully',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        fetchUsers()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error deleting user',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  onMounted(() => {
    fetchUsers(this)
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'maintainer') return 'success'
    if (role === 'user') return 'info'
    if (role === 'admin') return 'primary'
    if (role === 'superAdmin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'user') return 'UserIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'admin') return 'Edit2Icon'
    if (role === 'superAdmin') return 'UserPlusIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (!status) return 'success'
    return 'secondary'
  }

  return {
    fetchUsers,
    tableColumns,
    refUserListTable,
    searchQuery,
    isBusy,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    users,
    filterOn,
    deleteThisUser,
  }
}

import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers() {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/list/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/list/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/user/add', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/user/update', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/user/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFaultCodes() {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/faultcodes')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateNotificationPreferences(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/user/updatealerts', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
